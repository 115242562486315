<template>
  <div class="level">
    <div class="level-item">
        <div class="card">
        <header class="card-header has-background-primary">
            <h4 class="card-header-title is-centered title is-4 has-text-white">Add a New Store</h4>
        </header>
        <div class="card-content">  
        <form ref="form" @submit.prevent="handleSubmit">
        <b-steps
            v-model="activeStep"
            :animated="isAnimated"
            :rounded="isRounded"
            :has-navigation="hasNavigation"
            :icon-prev="prevIcon"
            :icon-next="nextIcon"
            :label-position="labelPosition"
            :mobile-mode="mobileMode">

            <b-step-item step="" icon="store" label="Store" :clickable="isStepsClickable">
                <h1 class="title has-text-centered">Store Information</h1>
                <hr/>
                <div class="columns">
                    <div class="column">
                        <b-field label="Store Name"
                            >
                            <b-input type="text"
                                icon="briefcase"
                                v-model="store.storeName"
                                maxlength="40"
                                required                   
                                >
                            </b-input>
                        </b-field>
                    </div>
                    <div class="column">
                        <b-field v-if="getActiveStore" label="Owned Corporation"
                            >
                            <b-checkbox v-model="companyField"
                                :true-value="getActiveStore.companyName"
                                false-value="Complete Corporation details on next page"
                                > 
                                {{ companyField }} 
                            </b-checkbox>
                        </b-field>

                    </div>
                </div>

                <div class="columns">
                    <div class="column">
                        <b-field label="Email">
                            <b-input type="email"
                                icon="envelope"
                                v-model="store.email"
                                maxlength="100"
                                required                   
                                >
                            </b-input>
                        </b-field>
                    </div>
                    <div class="column">
                        <b-field label="Mobile Phone">
                            <b-input type="tel"
                                icon="mobile"
                                v-model="store.cellNumber"
                                maxlength="30"
                                required                   
                                >
                            </b-input>
                        </b-field>
                    </div>
                    <div class="column">
                        <b-field label="Office Phone">
                            <b-input type="tel"
                                icon="mobile"
                                v-model="store.officeNumber"
                                maxlength="30"
                                required                   
                                >
                            </b-input>
                        </b-field>
                    </div>
                </div>

                <div class="columns">
                    <div class="column">
                        <b-field label="Street">
                            
                            <b-input type="text"
                                icon="address-card"
                                v-model="store.addressLine1"
                                maxlength="30" 
                                required                  
                                >
                            </b-input>
                        </b-field>   
                    </div>
                    <div class="column">
                        <b-field label="City">
                            
                            <b-input type="text"
                                icon="address-card"
                                v-model="store.city"
                                maxlength="30" 
                                required                  
                                >
                            </b-input>
                        </b-field>   
                    </div>
                </div>

                <div class="columns">

                    <div class="column">
                        <b-field label="State">                            
                            <b-select
                                placeholder="State"
                                icon="map"
                                v-model="store.state">
                                <option
                                    v-for="state in states"
                                    :value="state[0]"
                                    :key="state[0]">
                                    {{ state[1] }}
                                </option>
                            </b-select>
                        </b-field>

                    </div>
                    <div class="column">
                        <b-field label="Zip">
                            
                            <b-input type="text"
                                icon-pack="fas"
                                icon="address-card"
                                v-model="store.zipCode"
                                maxlength="10"
                                required                   
                                >
                            </b-input>
                        </b-field>
                    </div>
                    <div class="column">
                    <b-field label="Country">
                        <b-select 
                            placeholder="Select Country" 
                            v-model="store.country" 
                            required>
                            <option
                                v-for="country in countries"
                                :value="country[0]"
                                :key="country[0]">
                                {{ country[1] }}
                            </option>
                        </b-select>		                               
                    </b-field>
                    </div>
                    <div class="column">
                        <b-field label="TimeZone">
                            <b-select 
                                placeholder="Select Time Zone" 
                                v-model="store.timezone"
                                icon="clock"
                                required>
                                <option
                                    v-for="zone in zones"
                                    :value="zone[0]"
                                    :key="zone[0]">
                                    {{ zone[1] }}
                                </option>
                            </b-select>		        
                        </b-field>
                    </div>
                </div>

                <b-notification v-if="showCompany" class="is-info" :closable="isClosable">
                   A new company will be created for this store.  Please complete the company information on next page.
                </b-notification>

                <b-notification v-else class="is-success" :closable="isClosable">
                   New store will be owned by Company - <strong> {{ getActiveStore.companyName }} </strong>
                </b-notification>
            </b-step-item>


            <b-step-item icon="building" :visible="showCompany" step="" label="Company" :clickable="isStepsClickable">
                <h1 class="title has-text-centered">Company Information</h1>
                <hr/>
                <div class="columns">
                    <div class="column">
                        <b-field label="Company Name">
                            <b-input type="text"
                                icon="briefcase"
                                v-model="company.name"
                                maxlength="40"
                                required                   
                                >
                            </b-input>
                        </b-field>
                    </div>
                    <div class="column">
                        <b-field label="Email">
                            <b-input type="email"
                                icon="envelope"
                                v-model="company.companyEmail"
                                maxlength="100"
                                required                   
                                >
                            </b-input>
                        </b-field>
                    </div>
                    <div class="column">
                        <b-field label="Phone">
                            <b-input type="tel"
                                icon="mobile"
                                v-model="company.cellNumber"
                                maxlength="30"
                                required                   
                                >
                            </b-input>
                        </b-field>
                    </div>

                </div>

                <div class="columns">
                    <div class="column">
                        <b-field label="First Name">
                            <b-input type="text"
                                icon="user-tie"
                                v-model="company.firstName"
                                maxlength="30"
                                required                  
                                >
                            </b-input>
                        </b-field>
                    </div>
                    <div class="column">
                        <b-field label="Last Name">
                            <b-input type="text"
                                icon="user-tie"
                                v-model="company.lastName"
                                maxlength="30"
                                required                
                                >
                            </b-input>
                        </b-field>
                    </div>
                    <div class="column">
                        <b-field label="FEIN">
                            <b-input type="text"
                                icon="fa-landmark"
                                v-model="company.fein"
                                maxlength="10"
                                required                   
                                >
                            </b-input>
                        </b-field>    
                    </div>
                    <div class="column"  v-if='showDUN'>
                        <b-field label="DUN">
                            <b-input type="text"
                                icon="scroll"
                                v-model="company.dun"
                                maxlength="10"                   
                                >
                            </b-input>
                        </b-field>
                    </div>
                </div>

               <div class="columns">
                    <div class="column">
                        <b-field label="Street">
                            <b-input type="text"
                                icon="address-card"
                                v-model="company.addressLine1"
                                maxlength="30" 
                                required                  
                                >
                            </b-input>
                        </b-field>   
                    </div>
                    <div class="column">
                        <b-field label="City">
                            <b-input type="text"
                                icon="address-card"
                                v-model="company.city"
                                maxlength="30" 
                                required                  
                                >
                            </b-input>
                        </b-field>   
                    </div>
                    <div class="column">
                         <b-field label="State">                            
                            <b-select
                                required
                                placeholder="State"
                                icon="map"
                                v-model="company.state">
                                <option
                                    v-for="state in states"
                                    :value="state[0]"
                                    :key="state[0]">
                                    {{ state[1] }}
                                </option>
                            </b-select>
                            <!-- <select-states v-model="company.state"></select-states> -->
                        </b-field>

                    </div>
                    <div class="column">
                        <b-field label="Zip">
                            <b-input type="text"
                                icon-pack="fas"
                                icon="address-card"
                                v-model="company.zipCode"
                                maxlength="10"
                                required                   
                                >
                            </b-input>
                        </b-field>
                    </div>
                    <div class="column">
                    <b-field label="Country">
                        <b-select 
                            placeholder="Select Country" 
                            v-model="company.country" 
                            required>
                            <option
                                v-for="country in countries"
                                :value="country[0]"
                                :key="country[0]">
                                {{ country[1] }}
                            </option>
                        </b-select>		                               
                    </b-field>
                    </div>
                </div>

            </b-step-item>

            <b-step-item step="" v-if="setupBankPayment" icon="credit-card" label="Payment" :visible="showPayment" :clickable="isStepsClickable" :type="{'is-success': isProfileSuccess}">
                <h1 class="title has-text-centered">Bank Account Information</h1>
                <hr/>
                    <div class="columns">
                        <div class="column">
                        <b-field label="Account Holder">
                            <b-input type="text"
                                icon="user-tie"
                                v-model="bank.accountHolderName"
                                maxlength="30" 
                                required                  
                                >
                            </b-input>	                               
                        </b-field>
                        </div>
                        <div class="column">
                        <b-field label="Account Type">
                            <b-select
                                required
                                icon="map"
                                v-model="bank.accountHolderType">
                                <option
                                    v-for="type in bankAccountHolderTypes"
                                    :value="type[0]"
                                    :key="type[0]">
                                    {{ type[1] }}
                                </option>
                            </b-select>                               
                        </b-field>  
                        </div>
                        <div class="column">
                        <b-field label="Country">
                            <b-select
                                icon="flag"
                                placeholder="Select Country" 
                                v-model="bank.countryCode" 
                                required>
                                <option
                                    v-for="country in countries"
                                    :value="country[0]"
                                    :key="country[0]">
                                    {{ country[1] }}
                                </option>
                            </b-select>		                               
                        </b-field>
                        </div>
                    </div>

                    <div class="columns">
                        <div class="column">
                        <b-field label="Bank Routing Number">
                            <b-input type="text"
                                icon="university"
                                v-model="bank.routingNumber"
                                maxlength="30" 
                                required                  
                                >
                            </b-input>	                               
                        </b-field>
                        </div>
                        <div class="column">
                        <b-field label="Account Number">
                            <b-input type="text"
                                icon="money-check"
                                v-model="bank.accountNumber"
                                maxlength="30" 
                                required                  
                                >
                            </b-input>	                               
                        </b-field>     
                        </div>
                    </div>
                    <b-notification class="is-info-light" :closable="isClosable">
                        <strong>Bank account verification</strong> <br> 
                        <ul>
                            <li>
                                Within 2-3 business days, 2 deposits of small amounts will be made to your bank account
                            </li>
                            <li>
                                Log in to this site and enter the deposit amounts to complete the account verification
                            </li>
                        </ul>
                    </b-notification>
            </b-step-item>


            <b-step-item step="" icon="flag-checkered" label="Finish" :clickable="isStepsClickable" disabled>
                <h1 class="title has-text-centered">Finish</h1>
                <hr/>
                <div class="columns">

                    <div class="column columns-same-height is-one-third">
                        <div class="box">
                            <p>
                                <strong>Store Name :</strong> {{ store.storeName }}
                                    <b-tag v-if="store.storeName.length == 0" class="is-danger">Error</b-tag>

                                <span v-if="!showCompany"><br><strong>Company :</strong>{{ getActiveStore.companyName }}</span><br>
                                    
                                <strong>Email :</strong> {{ store.email }}
                                    <b-tag v-if="!isEmailValid(store.email)" class="is-danger">Error</b-tag><br>
                                    
                                <strong>Cell Phone:</strong> {{ store.cellNumber }}
                                    <b-tag v-if="store.cellNumber.length == 0" class="is-danger">Error</b-tag><br>
                                    
                                <strong>Office Phone :</strong>{{ store.officeNumber }}
                                    <b-tag v-if="store.officeNumber.length == 0" class="is-danger">Error</b-tag><br>
                                    
                                <strong>Address :</strong> {{ store.addressLine1 }}
                                    <b-tag v-if="store.addressLine1.length == 0" class="is-danger">Error</b-tag><br>
                                    
                                <strong>City :</strong> {{ store.city }}
                                    <b-tag v-if="store.city.length == 0" class="is-danger">Error</b-tag><br>

                                <strong>State :</strong> {{ store.state }}
                                    <b-tag v-if="store.state.length == 0" class="is-danger">Error</b-tag><br>

                                <strong>Zip :</strong> {{ store.zipCode }}
                                    <b-tag v-if="store.zipCode.length == 0" class="is-danger">Error</b-tag><br>

                                <strong>Country :</strong> {{ store.country }}
                                    <b-tag v-if="store.country.length == 0" class="is-danger">Error</b-tag><br>
                                    
                                <strong>Time Zone :</strong>{{ store.timezone }}
                                    <b-tag v-if="store.timezone.length == 0" class="is-danger">Error</b-tag><br>
                                    
                            </p>
                        </div>
                    </div>

                    <div class="column columns-same-height is-one-third" v-if="showCompany">
                        <div class="box">
                            <p>
                                <strong>Company Name :</strong> {{ company.name }}
                                    <b-tag v-if="company.name.length == 0" class="is-danger">Error</b-tag><br>
                                    
                                <strong>Email :</strong> {{ company.companyEmail }}
                                    <b-tag v-if="!isEmailValid(company.companyEmail)" class="is-danger">Error</b-tag><br>
                                    
                                <strong>Cell Phone:</strong> {{ company.cellNumber }}
                                    <b-tag v-if="company.cellNumber.length == 0" class="is-danger">Error</b-tag><br>
                                    
                                <strong>FEIN :</strong>{{ company.fein }}
                                    <b-tag v-if="company.fein.length == 0" class="is-danger">Error</b-tag><br>
                                    
                                <strong>DUN:</strong> {{ company.dun }}
                                    <b-tag v-if="company.dun.length == 0" class="is-danger">Error</b-tag><br>
                                    
                                <strong>Address :</strong> {{ company.addressLine1 }}
                                    <b-tag v-if="company.addressLine1.length == 0" class="is-danger">Error</b-tag><br>
    
                                <strong>City :</strong> {{ company.city }}
                                    <b-tag v-if="company.city.length == 0" class="is-danger">Error</b-tag><br>

                                <strong>State :</strong> {{ company.state }}
                                    <b-tag v-if="company.state.length == 0" class="is-danger">Error</b-tag><br>

                                <strong>Zip :</strong> {{ company.zipCode }}
                                    <b-tag v-if="company.zipCode.length == 0" class="is-danger">Error</b-tag><br>                                                                    <strong>Country :</strong> {{ company.country }}
                            </p>
                        </div>
                    </div>

                    <div class="column columns-same-height is-one-third" v-if="setupBankPayment">
                        <div class="box">
                            <p>
                                <strong>Account Holder :</strong> {{ bank.accountHolderName }}
                                    <b-tag v-if="bank.accountHolderName.length == 0" class="is-danger">Error</b-tag>
                                    <br>
                                <strong>Account Type :</strong> {{ bank.accountHolderType }}
                                    <b-tag v-if="bank.accountHolderType.length == 0" class="is-danger">Error</b-tag>
                                    <br>                         
                                <strong>Routing Number :</strong> {{ bank.routingNumber }}
                                    <b-tag v-if="bank.routingNumber.length == 0" class="is-danger">Error</b-tag>
                                    <br>                                      
                                <strong>Account Number :</strong> {{ bank.accountNumber }}
                                    <b-tag v-if="bank.accountNumber.length == 0" class="is-danger">Error</b-tag>
                                    <br>
                                <strong>Country :</strong> {{ bank.countryCode }}
                                    <b-tag v-if="bank.countryCode.length == 0" class="is-danger">Error</b-tag>
                                    <br>
                                <!-- <strong>Currency :</strong> {{ bank.currencyCode }}
                                    <b-tag v-if="bank.currencyCode.length == 0" class="is-danger">Error</b-tag>
                                    <br> -->
                            </p>
                        </div>
                    </div>
                </div>

                <b-notification class="is-success" v-if="isFormComplete" :closable="isClosable">
                    Please confirm all information above is correct and <strong> click on Save to complete adding the store </strong>.
                </b-notification>
                <b-notification class="is-danger" v-else :closable="isClosable">
                    Some required information is missing or invalid. Please click on Previous button and make sure all information is accurate.
                </b-notification>
            </b-step-item>

            <template
                v-if="customNavigation"
                slot="navigation"
                slot-scope="{previous, next}">
                <b-button
                    class="px-10 px-10"
                    outlined
                    type="is-danger"
                    icon-pack="fas"
                    icon-left="backward"
                    :disabled="previous.disabled"
                    @click.prevent="previous.action">
                    Previous
                </b-button>
                <b-button
                    v-if="next.disabled"
                    type="is-primary"
                    icon-pack="fas"
                    icon-left="paper-plane"
                    :disabled="isFormComplete == false"
                    @click.prevent="handleSubmit">
                    Save
                </b-button>
                <b-button
                    v-else
                    outlined
                    type="is-success"
                    icon-pack="fas"
                    icon-right="forward"
                    :disabled="next.disabled"
                    @click.prevent="next.action">
                    Next
                </b-button>
            </template>
        </b-steps>
        </form>
        </div>
        </div>
        <div v-if="isLoading">
            <data-loading 
                :isLoading="isLoading" 
                :isFullPage="isFullPage"> 
            </data-loading>
        </div>
    </div>
  </div>
</template>

<script>

import UserStore from "../../../../store/UserStore"
// import SelectStates from "../../../components/app/SelectStates"
import axios from 'axios'
import router from "../../../../router"
import dataLoading from '../../../../components/app/DataLoading.vue'

export default {

    components: {
        dataLoading
    },

    data() {
        return {
            activeStep: 0,
            showContact: true,
            showDUN: true,
            showPayment: true,
            showUsers: true,
            isAnimated: true,
            isRounded: true,
            isStepsClickable: false,
            isClosable: false,
            isLoading: false,
            isFullPage: true,
            setupBankPayment: false,
            hasNavigation: true,
            customNavigation: true,
            isProfileSuccess: false,

            prevIcon: 'chevron-left',
            nextIcon: 'chevron-right',
            labelPosition: 'bottom',
            mobileMode: 'minimalist',
            firstName: 'N/A',
            lastName: 'N/A',
            dun: 'N/A',

            states: [ [ 'FL', 'Florida'], [ 'IL', 'Illinois'], [ 'IN', 'Indiana'],  [ 'MI', 'Michigan'], [ 'WI', 'Wisconsin']],
            countries: [ ['US', 'USA'], ['CA','Canada'], ['MX','Mexico']],
            zones: [ ['CST','Central Time'], ['EST', 'Eastern Time'], ['MST','Mountain Time'], ['PST','Pacefic Time']],
            bankAccountHolderTypes: [ [ "business", "Business"], [ "individual", "Individual"]],

            bank: {
                accountHolderName: "",
                accountHolderType: "individual",
                accountNumber: "",
                countryCode: "US",
                currencyCode: "usd",
                routingNumber: ""            
            },

            store: {
                storeName: '',
                email: '',
                cellNumber: '',
                officeNumber: '',
                addressLine1: '',
                city: '',
                state: 'IL',
                zipCode: '',
                country: 'US',
                timezone: 'CST',
                companyId: '',
                active: true,
            },
            company: {
                name: '',
                companyEmail: '',
                cellNumber: '',
                firstName: '',
                lastName: '',
                fein: '',
                dun: '',
                addressLine1: '',
                city: '',
                state: 'IL',
                zipCode: '',
                country: 'US',
                active: true,
                
            },
            companyField: 'New Company',
        }
    },

    methods: {
        handleSubmit() {

            if (this.isFormComplete) {

                this.isLoading = true
                
                this.saveStore()

            } else {

                this.$buefy.notification.open({
                        message: 'Please make sure all required data is entered !',
                        type: 'is-danger',
                        duration: 6000,
                        'has-icon': true,
                        'icon-pack': 'fas',
                        icon: 'exclamation-circle'
                    })
                    
            }

        },

        saveStore() {

            axios.post(process.env.VUE_APP_API_ROOT_URL + 'users/' + UserStore.getters.getUser.id + '/stores/create', {
                company: this.company,
                store: this.store,
                bank: this.bank
            },
            {
                headers: {
                    'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                    'Refresh-Token': UserStore.getters.getRefreshToken
                },
            })
            .then( (response) => {
                console.log(response)
                if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                console.log(response.data.id)
                UserStore.commit('setUser',response.data)
                UserStore.commit('setActiveStoreDetails',response.data.activeStore);
                this.isLoading = false
                router.push({ path: 'home' })

            })
            .catch( (err) => {
                if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.data)
                this.isLoading = false
                if (err.response.status === 403) {
                    alert('Session Expired. Please log in again!')
                    UserStore.commit('userLogout')
                } else {
                    this.$buefy.notification.open({
                            message: 'Failed to save store information. Please contact support@veecli.com!',
                            type: 'is-danger',
                            duration: 6000,
                            'has-icon': true,
                            'icon-pack': 'fas',
                            icon: 'exclamation-circle'
                        })                    
                }
            })

        },

        setCompanyId(id) {
            this.company.id = id
            this.store.companyId = id
        },

   
        isEmailValid(email) {
            return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
        },

        clearCompanyId() {
            this.company.id = null
        }

    },

    computed: {
        getActiveStore() {

            return UserStore.getters.getActiveStore

            // if (UserStore.getters.getActiveStore) {
            //     return UserStore.getters.getActiveStore
            // } else {
            //     return false
            // }
        },

        showCompany() {
            if (UserStore.getters.getActiveStore) {
                this.clearCompanyId()
                return this.companyField != this.getActiveStore.companyName
            } else {
                return true
            }
        },

        isCompanyValid() {

            var companyValid = true
            
            if (this.showCompany) {

                for (const [key, value] of Object.entries(this.company)) {
                    if (value == "") {
                        if ( key !='id' ) {
                            companyValid = false
                        }
                    }
                    if ( key == 'companyEmail') {
                        companyValid = this.isEmailValid(value)
                    }
                }
            } else {
                this.setCompanyId(this.getActiveStore.companyId)
            }
            return companyValid
        },

        isStoreValid() {
            var storeValid = true

            if (! this.showCompany) {
                this.setCompanyId(this.getActiveStore.companyId)
            }

            for (const [key, value] of Object.entries(this.store)) {
                if (value == "" && key != 'companyId') {
                    storeValid = false
                }
                if ( key == 'email') {
                    storeValid = this.isEmailValid(value)
                }
            }
            return storeValid
        },

        isBankAccountValid() {
            var bankValid = true
            if (this.setupBankPayment) {
                for (const [key, value] of Object.entries(this.bank)) {
                    if (value == "" && key != 'accountNumberLastFour') {
                        bankValid = false
                    }
                }
            }
            return bankValid
        },

        isFormComplete() {
            if (this.isCompanyValid && this.isStoreValid && this.isBankAccountValid) {   
                return true
            } else {
                return false
            }         

        },      

    },

    mounted() {

        if (this.getActiveStore) {
            this.companyField = this.getActiveStore.companyName
        }
        
    }
}
</script>

<style>
.columns-same-height {
  display: flex;
}
</style>